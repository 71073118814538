<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h3 class="text-primary mb-5 text-center">Edit Organization</h3>
              <div class="form-group">
                <label for="name"
                  >Name<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="type name.."
                  v-model="name"
                />
              </div>
              <div class="form-group">
                <label for="name"
                  >Legal Name<span class="text-danger">*</span> :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="type legal name.."
                  v-model="legal_name"
                />
              </div>
              <div class="form-group">
                <label for="name">Accounting ID :</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="type accounting ID.."
                  v-model="accounting_id"
                />
              </div>
              <div class="form-group">
                <label for="group">Organization Status :</label>
                <b-form-select
                  class="form-control"
                  id="group"
                  v-model="group"
                  :options="group_option"
                >
                </b-form-select>
              </div>
              <button
                type="submit"
                class="btn btn-block btn-primary mt-5"
                @click="updateOrganization()"
              >
                <i class="fa fa-pencil"></i>
                Update Organization
              </button>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';

const organizationStatus = Object.entries(
  constant.ORGANIZATION_STATUS_FORM,
).map((val) => ({
  text: val[1],
  value: val[1].toLowerCase(),
}));

export default {
  name: 'EditOrganization',
  data() {
    return {
      name: '',
      legal_name: '',
      accounting_id: '',
      group: 'new',
      group_option: [...organizationStatus],
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.organization.item,
      isError: (state) => state.organization.isError,
      successMessage: (state) => state.organization.successMessage,
      errorMessage: (state) => state.organization.errorMessage,
    }),
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getOrganization();
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/organitation');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
  created: function() {
    const vendorID = this.$route.params.id;
    this.fetchOrganizationById({ id: vendorID });
  },
  methods: {
    ...mapActions('organization', [
      'fetchOrganizationById',
      'editOrganization',
    ]),

    async getOrganization() {
      const data = this.item;
      this.name = data.name;
      this.legal_name = data.legal_name;
      this.accounting_id = data.accounting_id;
      this.group = data.status;
    },

    async updateOrganization() {
      const organizationID = this.$route.params.id;
      let payload = {
        id: organizationID,
        name: this.name,
        legal_name: this.legal_name,
        accounting_id: this.accounting_id,
        status: this.group,
      };

      this.editOrganization(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
